import http from "@/axios/index"
import { URI } from "@/config/axios"
import store from "@/store/index"

export const getModelList = (params = {}) => {
    return http({
        url: "/hanyun-bigData/"+ `api/v1/algorithmModel/queryPage`,
        method: 'get',
        params: {
            ...params
        },
        headers: {
            Authorization: `Bearer ${store.getters.platformToken}`
        }
    })
}

// 获取模型配置
export const getModelConfig = (modelId)=> {
    return http({
        url: URI.system + `api/aiModelConfig/findByModelId/${modelId}`,
        method: 'get'
    })
}


// 获取模型网关
export const getModelGateway = (params = {})=> {
    return http({
        url: URI.system + `api/model/gateways`,
        method: 'get',
        params: {
            ...params
        },
    })
}

// 获取模型网关下的设备
export const getModelDevice = (params = {})=> {
    return http({
        url: URI.system + `api/relations/device/model/info`,
        method: 'get',
        params: {
            ...params
        },
    })
}

// 保存模型配置
export const saveModelConfig = (params = {})=> {
    return http({
        url: URI.system + `api/aiModelConfig/save`,
        method: 'post',
        data: params,
    })
}


// 获取设备点位
export const getDeviceTag = (id)=> {
    return http({
        url: URI.system + `api/plugins/telemetry/DEVICE/${id}/values/attributes/CLIENT_SCOPE?keys=tagListJsonStr`,
        method: 'get',
    })
}

// 下发
export const saveModelIssue = (params = {})=> {
    return http({
        url: URI.system + `api/modelDeviceRecord/add`,
        method: 'post',
        data: params,
    })
}
