<template>
    <div class="model-distribution-manager">
        <els-bread class="_bread"></els-bread>
        <div class="_header d-header">
            <el-button type="primary" size="small" @click="reloadTable">{{lang.refresh}}</el-button>
            <div class="h--search">
                <el-input
                        v-model="searchValue"
                        prefix-icon="el-icon-search"
                        :placeholder="lang.modelCNEN"
                        size="small"
                ></el-input>
            </div>
        </div>
        <div class="_content d-content">
            <els-table
                    ref="tableRefs"
                    :column="column"
                    :onload="onload"
                    menuWidth="180px">
                <template v-slot:menu="{ row }">
                    <el-button
                            type="text"
                            @click.stop="handleRowIssue(row)"
                    >{{lang.conDis}}
                    </el-button>
                </template>
            </els-table>
        </div>
        <el-dialog :title="dialogTitle"
                   :visible.sync="dialogVisible" :close-on-click-modal="false"
                   width="840px">
            <el-tabs v-model="activeName" v-if="dialogVisible">
                <el-tab-pane :label="lang.modelConf" name="1">
                    <els-form
                            :ctx="{lang: $t('d')}"
                            ref="elsFormRefModel"
                            v-model="modelFormData"
                            :column="modelFormColumn"
                            :itemKey="(d)=> d.required + ''"
                            @change-requestType="changeRequestType"
                    ></els-form>
                </el-tab-pane>
                <el-tab-pane :label="lang.issueGateway" name="2">
                    <els-table
                            ref="dialogTableRefs"
                            :column="modelTableColumn" class="modelElsTable"
                            :table="{ rowKey:'code', lazy:true, load:loadModelData, treeProps:{children: 'children', hasChildren: 'hasChildren'}}"
                            :onload="modelTableOnload" @change-leaf="selCheck"  @row-click="rowClick"
                            idKey="code"
                            :isLeaf="(n) => !n.hasChildren"
                            :showCheckbox="true" :showIndex="false" :showMenu="false">
                        <template v-slot:tagName="{ row }">
                            <el-select v-model="row.tagName" filterable placeholder="" style="width: 100%"
                                       collapse-tags
                                       multiple size="small" @focus="getTagData(row)" v-if="row.hasTag">
                                <el-option
                                        v-for="item in tagListData"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </template>
                    </els-table>
                </el-tab-pane>
            </el-tabs>
            <div slot="footer" class="dialog-footer">
                <div class="issue--num--text" v-if="activeName=='2'">{{lang.selDevice}}: {{selTableData.length}}</div>
                <el-button @click="dialogVisible = false" class="btn-normal">{{lang.cancel}}</el-button>
                <el-button type="primary" @click="handleSaveCon" class="btn-normal" v-if="activeName=='1'">
                    {{lang.save}}
                </el-button>
                <el-button type="primary" @click="handleSubmit" class="btn-normal" v-if="activeName=='2'">
                    {{lang.issue}}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import elsBread from "@/components/els-breadcrumb/main.vue";
    import elsTable from "@/components/els-table/main.vue";
    import mixinsI18n from "@/mixins/i18n.js";
    import elsFormDialog from "@/components/els-form-dialog/main.vue";
    import elsForm from "@/components/els-form/main.vue";
    import {
        getModelList,
        getModelConfig,
        getModelGateway,
        getModelDevice,
        saveModelConfig,
        getDeviceTag, saveModelIssue
    } from "@/api/manager/model-distribution-manager.js";

    export default {
        name: "model-distribution-manager",
        mixins: [mixinsI18n],
        components: {elsBread, elsTable, elsFormDialog, elsForm},
        data: () => {
            return {
                PAGE_NAME: "modelIssueManagePage",
                columnBread: [],
                searchValue: "",
                dialogTitle: "",
                activeName: "1",
                dialogVisible: false,
                modelFormData: {},
                selTableData: [],  // 弹框中选择的表格数据
                selModel: {}, // 当前选择模型
                tagListData: [], // 点位数据
            }
        },
        computed: {
            ...mapState(["breadMenus"]),
            column() {
                const nameCn = {prop: "nameCn", label: this.lang.cnName};
                const nameEn = {prop: "nameEn", label: this.lang.enName};
                const version = {prop: "modelVersion", label: this.lang.version};
                const explain = {prop: "description", label: this.lang.modelDes};
                const updateTime = {prop: "updateTime", label: this.lang.lastUpdate};
                return [nameCn, nameEn, version, explain, updateTime]
            },
            modelFormColumn() {
                const ln = this.$i18n.locale;
                const thrTrigger = {
                    tag: "div",
                    class: "thrTrigger--content",
                    cls: [{
                        tag: "el-switch",
                        prop: "thresholdFlag",
                        label: this.lang.thrTrigger,
                        labelWidth: ln=='zh-cn'?"114px":"160px",
                        value: false
                    }, {
                        tag: "div",
                        class: "red--value",
                        style: `display:${this.modelFormData.thresholdFlag ? '' : 'none'}`,
                        cls: this.lang.thresholdMess,
                    }]
                };
                const threshold = {
                    tag: "div",
                    class: "threshold--content",
                    cls: [{
                        prop: "thresholdMin",
                        label: this.lang.lowerThr,
                        labelWidth: ln=='zh-cn'?"100px":"200px",
                        rules: [{pattern: /^[-+]?[0-9]+([.]{1}[0-9]+){0,1}$/, message: this.lang.lowerMess},
                            {
                                validator: (rule, value, callback) => {
                                    if (value&&value.toString().length>10) {
                                        callback(new Error(this.lang.max10));
                                        return;
                                    }
                                    callback();
                                }, trigger: 'blur'
                            }
                            ],
                    }, {
                        prop: "thresholdMax",
                        label: this.lang.upperThr,
                        labelWidth: ln=='zh-cn'?"100px":"210px",
                        rules: [{pattern: /^[-+]?[0-9]+([.]{1}[0-9]+){0,1}$/, message: this.lang.lowerMess},
                            {
                                validator: (rule, value, callback) => {
                                    if (this.modelFormData.thresholdMin && Number(value) && Number(value) < this.modelFormData.thresholdMin) {
                                        callback(new Error(this.lang.upperMess));
                                        return;
                                    }
                                    if (value&&value.toString().length>10) {
                                        callback(new Error(this.lang.max10));
                                        return;
                                    }
                                    callback();
                                }, trigger: 'blur'
                            }],
                    }, {
                        prop: "thresholdLastTime",
                        label: this.lang.thrDuration + "(S)",
                        labelWidth: ln=='zh-cn'?"150px":"260px",
                        rules: [{pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/, message: this.lang.lowerMess},
                            {
                                validator: (rule, value, callback) => {
                                    if (value&&value.toString().length>10) {
                                        callback(new Error(this.lang.max10));
                                        return;
                                    }
                                    callback();
                                }, trigger: 'blur'
                            }
                        ],
                    }]
                };
                const thrInterface = {
                    tag: "el-switch",
                    prop: "requestFlag",
                    label: this.lang.triInter,
                    labelWidth: ln=='zh-cn'?"114px":"160px",
                    value: false
                };
                const request = {
                    tag: "el-select",
                    prop: "requestType",
                    label: this.lang.reqMode,
                    labelWidth: ln=='zh-cn'?"114px":"160px",
                    required: this.modelFormData.requestFlag ? true : false,
                    cls: [{value: 'POST'}, {value: 'GET'}, {value: 'PUT'}]
                };
                const url = {
                    prop: "requestUrl",
                    label: this.lang.interAdd,
                    labelWidth: ln=='zh-cn'?"114px":"160px",
                    required: this.modelFormData.requestFlag ? true : false,
                    rules: [{max: 50, message: this.lang.max50, trigger: "blur"}]
                };
                const headers =
                    {
                        tag: "item-table",
                        prop: "header",
                        label: "Headers",
                        labelWidth: ln=='zh-cn'?"114px":"160px",
                        required: this.modelFormData.requestFlag ? true : false,
                        format: this.transObjOrArray,
                        value: '{"Content-Type":"application/json"}',
                        rules: this.modelFormData.requestFlag? [{
                            key: [{required: true, message: this.lang.notNull},{max: 30, message: this.lang.max30}],
                            value: [{required: true, message: this.lang.notNull},{max: 30, message: this.lang.max30}]
                        }] : [],
                        showMenu: true,
                        showSaveBtn: false,
                        showPagination: false,
                        column: [
                            {
                                label: "Header",
                                prop: "key",
                                form: {tag: "el-input"}
                            },
                            {
                                label: "Value",
                                prop: "value",
                                form: {tag: "el-input"}
                            },
                        ],
                    }
                return [thrTrigger, threshold, thrInterface, request, url, headers]
            },
            driverStatus() {
                return [{
                    label: "", value: 0
                }, {
                    label: this.lang.success, value: 1, className: "blue-color"
                }, {
                    label: this.lang.fail, value: 2, className: "red-color"
                }, {
                    label: this.lang.success, value: 3, className: "blue-color"
                }, {
                    label: this.lang.fail, value: 4, className: "red-color"
                }]
            },
            modelTableColumn() {
                const num = {prop: "code", label: this.lang.gateNum};
                const name = {prop: "name", label: this.lang.gateName};
                const sel = {prop: "tagName", label: this.lang.pointSel, hasSlot: true, minWidth: 120};
                const version = {prop: "modelVersion", label: this.lang.disVersion};
                const result = {prop: "modelStatus", label: this.lang.issueResults, dict: this.driverStatus};
                return [num, name, sel, version, result]
            }
        },
        watch: {
            searchValue(v) {
                this.reloadTable();
            },
        },
        methods: {
            transObjOrArray(v) {
                if (!v) return v;
                if (Array.isArray(v)) {
                    let res = {};
                    v.forEach(c => {
                        if (c.key != undefined) {
                            res[c.key] = c.value
                        }
                    })
                    return JSON.stringify(res);
                }
                if (typeof v === 'string') {
                    v = JSON.parse(v);
                    return Object.keys(v).map(k => ({key: k, value: v[k]}))
                }
                return v;
            },
            async onload(page, query) {
                try {
                    const p = {
                        pageNum: page.pageNum,
                        pageSize: page.pageSize,
                        onlineStatus: 1,
                        switchStatus: 1,
                        name: this.searchValue || null,
                    };
                    const res = await getModelList(p);
                    const list = res.result.list;
                    const total = Number(res.result.total) || res.result.list.length;
                    return {list, total}
                } catch (err) {
                    console.log(err);
                    return {list: [], total: 0};
                }
            },
            reloadTable() {
                this.$refs.tableRefs && this.$refs.tableRefs.apiOnload()
            },
            // 点击配置下发
            async handleRowIssue(row) {
                this.selModel = row;
                this.dialogVisible = true;
                this.dialogTitle = `${this.lang.conDis} (${row.nameCn}，${this.lang.currentVersion}：${row.modelVersion})`;
                this.activeName = '1';
                this.modelFormData = {};
                this.selTableData = [];
                const res = await getModelConfig(row.id);
                if (res && res.result) {
                    const {
                        id, thresholdFlag, thresholdLastTime, thresholdMax, thresholdMin,
                        requestFlag, requestType, requestUrl, header
                    } = res.result;
                    this.modelFormData = {
                        id, thresholdFlag, thresholdLastTime, thresholdMax, thresholdMin,
                        requestFlag, requestType, requestUrl, header
                    };
                }
            },
            // 点击弹框下发
            async handleSubmit() {
                if (!this.modelFormData.id) {
                    this.$message({type: "warning", message: this.lang.saveConfig});
                    return;
                }
                if (this.selTableData == 0) {
                    this.$message({type: "warning", message: this.lang.selDeviceFir});
                    return;
                }
                try {
                    const {parent, indeterminate} = this.$refs.dialogTableRefs.apiGetAllSelect();
                    const selTreeData = parent.concat(indeterminate);
                    const treeList = selTreeData.map(item => {
                        return {
                            deviceCode: item.code,
                            parentDeviceId: {id: item.id.id, entityType: item.id.entityType},
                            deviceList: item.children.filter(item1 => item1.$check).map(item2 => {
                                return {
                                    deviceCode: item2.code,
                                    deviceId: {id: item2.id, entityType: item2.entityType},
                                    tagName: item2.tagName?.toString() || ''
                                }
                            })
                        }
                    })
                    const data = {
                        ftpFileUrl: this.selModel.ftpFileUrl,
                        modelId: this.selModel.id,
                        list: treeList
                    }
                    const res = await saveModelIssue(data);
                    this.$message({type: "success", message: this.lang.operSucc});
                    this.$refs.dialogTableRefs.apiClearSelection();
                    this.$refs.dialogTableRefs.apiOnload();
                    this.selTableData = [];
                } catch (err) {
                    console.log(err);
                }
                // this.$refs.dialogTableRefs.apiOnload(undefined, {num: 456})
            },
            // 点击保存模型配置
            async handleSaveCon() {
                try {
                    await this.$refs["elsFormRefModel"].validate();
                    if (this.modelFormData.thresholdFlag) {
                        if (!this.modelFormData.thresholdMin && !this.modelFormData.thresholdMax) {
                            this.$message({type: "warning", message: this.lang.thresholdMess});
                            return;
                        }
                    }
                    const {id, ftpFileUrl, description, nameCn, nameEn, tenantId, modelVersion} = this.selModel;
                    const data = {
                        modelId: id,
                        ftpFileUrl,
                        description,
                        nameCn,
                        nameEn,
                        tenantId,
                        version: modelVersion
                    };
                    //  this.modelFormData = {id: this.modelFormData.id,thresholdFlag: false, requestFlag: false}
                    const res = await saveModelConfig({...this.modelFormData, ...data});
                    this.modelFormData.id = res.result?.id || this.modelFormData.id;
                    this.$message({type: "success", message: res.msg});
                } catch (err) {
                    console.log(err);
                }
            },
            //
            async modelTableOnload(page, query, data) {
                //   console.log(page, query, data)
                try {
                    const p = {
                        pageNum: page.pageNum,
                        pageSize: page.pageSize,
                        modelId: this.selModel.id
                    };
                    const res = await getModelGateway(p);
                    const list = res.result.data;
                    list.forEach(item => {
                        item.hasChildren = true;
                        item.children = [];
                    })
                    const total = res.result.totalCount || res.result.data.length;
                    return {list, total}
                } catch (err) {
                    console.log(err);
                    return {list: [], total: 0};
                }
            },
            selCheck(r) {
                this.selTableData = r;
            },
            rowClick(row, column, cell, event) {
                if (cell.getElementsByClassName('el-checkbox')?.length>0) {
                    event.currentTarget.querySelector(".el-table__expand-icon")?.click();
                }
            },
            async loadModelData(tree, treeNode, resolve) {
                const params = {
                    modelId: this.selModel.id,
                    fromId: tree.id.id
                };
                const res = await getModelDevice(params);
                const list = [];
                res.forEach(item => {
                    const obj = {
                        id: item.to.id,
                        entityType: item.to.entityType,
                        code: item.toCode,
                        name: item.toName,
                        tagName: item.tagName?item.tagName.split(','):[],
                        version: item.modelVersion,
                        modelStatus: 0,
                        hasTag: true
                    }
                    list.push(obj)
                })
                resolve(list);
                this.$set(tree, 'children', list)
                // tree.children = list;
            },
            changeRequestType(v) {
                if (v) {
                    this.$refs["elsFormRefModel"].validateField("requestType");
                }
                this.$refs["elsFormRefModel"].validate()
            },
            // 获取点位数据
            async getTagData(row) {
                const res = await getDeviceTag(row.id);
                this.tagListData = [];
                if (res && res[0] && res[0].value) {
                    this.tagListData = JSON.parse(res[0].value) || []
                }
            }
        },
        mounted() {
            this.columnBread = this.breadMenus;
        }
    }
</script>

<style scoped lang="scss">
    .model-distribution-manager {
        width: 100%;
        height: 100%;

        .d-header {
            padding: 0 24px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
        }

        .d-content {
            padding: 24px;
            box-sizing: border-box;
            position: relative;
            width: 100%;
            height: calc(100% - 44px - 54px);
        }

        .dialog-footer {
            position: relative;

            .issue--num--text {
                position: absolute;
                left: 0;
                bottom: 10px;
            }
        }
    }

    ::v-deep .threshold--content {
        display: flex;
        margin-left: 34px;

        .el-form-item {
            display: flex;

            .el-form-item__label-wrap {
                margin-left: 0px !important;
                white-space: nowrap;
                padding-left: 12px;
            }

            .el-form-item__content {
                margin-left: 0px !important;
            }
        }
    }

    ::v-deep .thrTrigger--content {
        display: flex;

        .red--value {
            color: #909399;
            line-height: 40px;
            font-size: 12px;
            margin-left: 12px;
        }
    }

    ::v-deep .el-tab-pane {
        .self-table {
            height: 500px;
        }
    }

    ::v-deep .el-dialog .el-dialog__header {
        min-height: 44px;
        height: auto !important;
        padding: 8px 24px !important;
        box-sizing: border-box;

        .el-dialog__title {
            word-break: break-all; //英文
            white-space: pre-wrap; //中文
            padding-right: 4px;
        }
    }

    ::v-deep .els-form--item-table {
        .item-table--elem.is-error {
            .el-input__inner {
                border: 1px solid red;
            }
        }
    }
    ::v-deep .modelElsTable .el-table  .el-table__header-wrapper{
                tr th:first-child .el-checkbox {
                    display: none;
                }
    }
    ::v-deep .blue-color {
        color: #409EFF;
    }

    ::v-deep .red-color {
        color: red
    }
</style>
